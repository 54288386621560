import React from 'react'
import { Link } from 'gatsby'
import * as images from 'images/home'
import Helmet from 'react-helmet'
import { heroKlantenservice } from 'images/hulp/klantenservice'
import Layout from '../../layouts'

import config from '../../config'
const { appUrl, signupRoute } = config

import Checkmark from 'components/misc/checkmark'
const {
  logos: { woodwatch, petossi, storageShare, codaisseur },
} = images

class OverstapservicePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: '',
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id,
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="demo animated fadeInPage">
          <Helmet>
            <title>
              Employes overstapservice | Eenvoudig en kostenloos| heel het jaar
              mogelijk
            </title>
            <meta
              name="description"
              content="Employes biedt met de Overstapservice de mogelijkheid om op ieder moment van het jaar eenvoudig en kosteloos over te stappen van salarisadminstratie. Bekijk de mogelijkheden en stap over."
            />

            <meta
              itemprop="name"
              content="Alles over de Employes Overstapservice"
            />

            <meta
              itemprop="description"
              content="Employes biedt met de Overstapservice de mogelijkheid om op ieder moment van het jaar eenvoudig en kosteloos over te stappen van salarisadminstratie. Bekijk de mogelijkheden en stap over."
            />

            <meta itemprop="image" content="/static/meta-img.jpg" />

            {/* OpenGraph tags */}
            <meta
              property="og:url"
              content="https://employes.nl/overstapservice/"
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Alles over de Employes Overstapservice"
            />
            <meta
              property="og:description"
              content="Employes biedt met de Overstapservice de mogelijkheid om op ieder moment van het jaar eenvoudig en kosteloos over te stappen van salarisadminstratie. Bekijk de mogelijkheden en stap over."
            />
            <meta
              property="og:image"
              content="https://employes.nl/static/meta-img.jpg"
            />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Alles over de Employes Overstapservice"
            />
            <meta
              name="twitter:description"
              content="Employes biedt met de Overstapservice de mogelijkheid om op ieder moment van het jaar eenvoudig en kosteloos over te stappen van salarisadminstratie. Bekijk de mogelijkheden en stap over."
            />
            <meta
              name="twitter:image"
              content="https://employes.nl/static/meta-img.jpg"
            />
          </Helmet>

          <header className="header padding-l">
            <div className="container-md relative">
              <div className="grid yg align-middle margin-l-bottom center">
                <div className="col-12">
                  <div className="title margin-s-bottom">
                    <h5 className="eyebrow turq no-margin">
                      Op ieder moment overstappen
                    </h5>
                    <h1>Employes Overstapservice</h1>
                    <p>
                      Overstappen van salarisadministratie kan een heel gedoe
                      zijn. Zeker als je het nog niet zelf in de hand hebt.
                      Gedurende het jaar overtstappen wordt door een accountant
                      vaak als onverstandig of te ingewikkeld beschouwd. Maar
                      dat hoeft het helemaal niet te zijn, vinden wij. Vandaar
                      dat wij met het initiatief “De Employes Overstapservice”
                      komen.
                    </p>
                  </div>
                </div>

                <div className="col-12 margin-l-bottom">
                  <h2>Wat is het?</h2>
                  <p className="margin-s-bottom">
                    Met de Employes Overstapservice stellen we je in staat op
                    ieder moment van het jaar over te stappen van je huidige
                    salarisadministratie naar de software van Employes. Ook als
                    groot bedrijf hoef je niet meer te wachten tot het begin van
                    het nieuwe jaar, maar kan je gewoon al in april, mei of
                    iedere andere dag van het jaar starten met je nieuwe
                    administratie in Employes. Wij hebben namelijk een methodiek
                    ontwikkeld waarmee we eenvoudig de salarisadministratie
                    kunnen vervolgen. Buiten het feit dat je heel het jaar over
                    kan stappen, zorgen we ook nog eens dat je hier zelf zo min
                    mogelijk voor hoeft te doen. Dat alles geheel vrijblijvend
                    en zonder extra kosten.
                  </p>

                  <h2>Hoe werkt het?</h2>
                  <p className="margin-s-bottom">
                    Het overstappen met je salarisadministratie kan een aardige
                    klus zijn. Maar Employes zorgt ervoor dat je zelf bijna niks
                    hoeft te doen voor een overstap. We helpen je geheel
                    kosteloos op weg. Wij zorgen ervoor dat je hele
                    administratie netjes wordt ingericht en dat je soepel van
                    start kunt.
                  </p>

                  <h4 className="margin-s-bottom">
                    De Employes Overstapservice regelt het volgende
                  </h4>

                  <p>
                    <strong>
                      1. Wij controleren alle ingevoerde medewerkers
                    </strong>
                  </p>
                  <p className="margin-s-bottom">
                    Wij zorgen ervoor dat per medewerker de benodigde informatie
                    voor het uitvoeren van de loonverwerking en loonaangifte,
                    goed in ons systeem wordt ingesteld.
                  </p>

                  <p>
                    <strong>
                      2. Onze salarisexperts controleren jouw huidige
                      loonstroken
                    </strong>
                  </p>
                  <p className="margin-s-bottom">
                    Wij controleren de huidige loonstroken die je naar ons
                    opstuurt en filteren eventuele fouten eruit, zodat we de
                    juiste beginstanden kunnen overnemen.
                  </p>

                  <p>
                    <strong>3. Wij stellen Employes goed in</strong>
                  </p>
                  <p className="margin-s-bottom">
                    We nemen alle beginstanden over en richten je
                    salarisadministratie in zodat je op dezelfde voet verder
                    kan.
                  </p>

                  <p>
                    <strong>
                      4. Wij nemen alle reserveringen en andere zaken over
                    </strong>
                  </p>
                  <p className="margin-s-bottom">
                    Doordat Employes alle standen van de vorige
                    salarisadministratie overneemt, krijgen de werknemers geen
                    gesplitste jaaropgave, maar gewoon netjes één jaaroverzicht.
                    Deze komt aan het einde van het jaar beschikbaar in de
                    werknemersomgeving.
                  </p>

                  <p className="margin-s-bottom">
                    Nadat we alle standen hebben overgezet en gecontroleerd
                    activeren we je administratie en kan je meteen aan de slag.
                    Zonder vertraging.
                  </p>

                  <h2>Wat moet je doen?</h2>
                  <p className="margin-s-bottom">
                    Voordat het overstap team van Employes aan de slag kan met
                    het overzetten van jouw salarisadministratie is het
                    belangrijk dat je een account hebt aangemaakt op de
                    applicatie van Employes. Nadat je alle stappen hebt
                    doorlopen hoef je alleen nog maar de laatste loonstroken te
                    uploaden van je oude salarisadministratie. Vanaf nu regelen
                    wij de rest.
                  </p>

                  <h4 className="margin-s-bottom">Dit doe je zelf</h4>

                  <p>
                    <strong>1. Maak een Employes account aan</strong>
                  </p>
                  <p className="margin-s-bottom">
                    Maak een account aan voor jouw bedrijf en vul je
                    bedrijfsgegevens in.
                  </p>

                  <p>
                    <strong>2. Vul je medewerkers in</strong>
                  </p>
                  <p className="margin-s-bottom">
                    Voeg je medewerkers toe in je salarisadministratie van
                    Employes en vul de persoonsgegevens in.
                  </p>

                  <p>
                    <strong>3. Upload je laatste loonstroken</strong>
                  </p>
                  <p className="margin-s-bottom">
                    Upload de laatste loonstroken per medewerker van je vorige
                    salarisadministratie naar Employes.
                  </p>

                  <p className="margin-s-bottom">
                    Wanneer we de loonstroken hebben ontvangen gaan we meteen
                    aan de slag met het zo snel mogelijk omzetten van de
                    administratie. Je kan binnen no time genieten van het gemak
                    van Employes.
                  </p>
                  <br />
                  <br />
                  <a
                    className="btn primary lg margin-l-bottom"
                    href={appUrl + signupRoute}
                    rel="nofollow"
                  >
                    Direct overstappen
                  </a>

                  <h2>Neem contact op</h2>
                  <p className="margin-s-bottom">
                    Heb je extra hulp nodig bij het overstappen? Neem direct
                    telefonisch contact op met ons Overstapteam op{' '}
                    <a href="tel:0208085481">tel. 020-8085481</a>. We zijn
                    bereikbaar van maandag tot en met vrijdag tussen 9.00 en
                    17.00 uur. Of mail naar{' '}
                    <a href="mailto:overstap@employes.nl" className="">
                      overstap@employes.nl
                    </a>
                    . Meer lezen over overstappen naar Employes?{' '}
                    <Link to="/kennisbank/" className="link">
                      Bekijk de kennisbank
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </header>
        </main>
      </Layout>
    )
  }
}

export default OverstapservicePage
